



























































































































































































@import '~@/styles/import';

.room-selection {
  @apply grid gap-24;

  @media (min-width: 1280px) {
    @apply grid-cols-2;
  }
}

