















































@import '~@/styles/import';
.floating-button-sidebar {
  @apply fixed bottom-16 left-16 h-64 w-full flex items-center justify-center px-30 py-22 bg-primary z-50 text-white rounded-32 font-semibold;
    max-width: calc(100% - 3.2rem);
  @media (min-width: 768px) {
    @apply fixed right-64 left-auto;
    width: calc(100% - 64px - 64px);
    max-width: 57.2rem;
  }
  &:disabled {
    @apply bg-grayBlue cursor-not-allowed;
  }
}
