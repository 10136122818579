@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if(
    $height,
    percentage($height/$ratio-or-width),
    percentage(1 / $ratio-or-width)
  );
  &:before {
    content: '';
    float: left;
    padding-bottom: $padding;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
