$primary: var(--color-primary);
$secondary: var(--color-secondary);
$text: var(--text-color);

$dark: #394045;
$gray: #a1abb3;
$grayBlue: #c8d6e0;
$grayLight: #f2eeee;
$grayVeryLight: #fafafa;
$light: #ffffff;
$error: #d54c4d;
$success: #1bc176;
