




















































































































































































































































































































































@import '~@/styles/import';

.addon-modal {
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;

  transition: 0.3s right;

  right: -700px;
  @apply max-w-700;

  &.right-0 {
    right: 0px;
  }

  .close-button {
    right: 2.4rem;
    top: 1.6rem;

    @media (min-width: 788px) {
      right: calc(700px + 2.4rem);
      top: 2.4rem;
    }
  }
}

.button {
  @apply bg-primary py-12 px-24 text-white rounded-32 inline-block font-medium;
  &:disabled {
    @apply bg-grayBlue cursor-not-allowed;
  }
}

.room-name {
  max-width: 80%;
}

.add-ons {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-column-gap: 3rem;

  @media (min-width: 768px) {
    /* minmax(0, 1fr) enforces equal column width - see: https://stackoverflow.com/questions/47601564/equal-width-columns-in-css-grid */
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    &-title {
      @apply col-span-2;
    }
  }
}

.add-on-count {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 4rem calc(100% - 10rem) 4rem;
  grid-column-gap: 1rem;
}

