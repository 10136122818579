
























































































































































































































































































































































































































































































































































































































































































































































































































































































@import '~@/styles/import';

.policy-tooltip {
  p:not(:first-child) {
    @apply mt-8;
  }
}

.backdrop {
  @apply bg-dark fixed top-0 bottom-0 left-0 right-0;
  // opacity: 0.8;
  filter: opacity(80%);
  backdrop-filter: saturate(90%) blur(2px);
  transition: 0.3s opacity;
}

.room-modal {
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  transition: 0.3s right;

  right: -700px;
  @apply max-w-700;

  .gallery-top {
    @apply w-full absolute left-0 top-0 block;
    height: 80%;
  }

  .gallery-thumbs {
    @apply box-border absolute block bottom-8 left-8 right-8;
    height: calc(20% - (0.8rem * 2));
    width: calc(100% - (0.8rem * 2));
  }

  .gallery-top {
    .swiper-slide {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  &--large {
    @media (min-width: 1360px) {
      --max-width: 100vw;

      @media (min-width: 1600px) {
        --max-width: clamp(700px, calc(100vw - 40rem), 2160px);

        .close-button {
          right: calc(100vw - 40rem + 2.4rem);
          right: calc(var(--max-width) + 2.4rem);
          top: 2.4rem;
        }
      }
      right: calc((100vw - 40rem) * -1);
      right: calc(var(--max-width) * -1);
      display: grid;
      //grid-template-columns: 1fr 2fr;
      //grid-template-columns: 1fr 1fr minmax(450px, 700px);
      grid-template-columns: minmax(350px, 1fr) minmax(350px, 1fr) minmax(350px, 1fr);
      //grid-template-rows: 72px calc(100vh - 72px);
      grid-template-rows: 100vh auto;
      max-width: calc(100vw - 40rem);
      max-width: var(--max-width);

      background: $grayLight;
      background: $text;

      .details {
        background: white;
        @apply border-l border-grayBlue;
        //@apply pt-128 row-start-1 col-start-1;
      }

      .swiper {
        @apply col-span-2;
        position: sticky;
        top: 0;
        margin: 2.4rem;
        width: calc(100% - (2.4rem * 2));
        box-sizing: border-box;
      }

      .gallery-top {
        height: 88%;
      }

      .gallery-thumbs {
        height: calc(12% - 1.6rem);
        @apply bottom-0 left-0 right-0 w-full;
      }

      .packages {
        @media (max-width: 2300px) {
          //@apply grid-cols-1;
        }
      }
    }
  }

  &.right-0 {
    right: 0px;
  }
}

.button {
  @apply bg-primary py-12 px-24 text-white rounded-32 inline-block font-medium text-14 whitespace-no-wrap;
  &:disabled {
    @apply bg-grayBlue cursor-not-allowed;
  }

  &.button {
    &--secondary {
      @apply bg-secondary;
    }
  }
}

.packages {
  @apply mt-24;
  @apply grid gap-x-16 gap-y-16;
  @apply grid-cols-1;
  @apply relative;

  @media (min-width: 768px) {
    //@apply grid-cols-2;
  }
}

.addons {
  &__list {
    li {
      @apply mb-8 flex gap-x-8;

      &:last-of-type {
        @apply mb-0;
      }
    }
  }
}

.package {
  @apply grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto min-content;

  &.disabled {
    @apply border-grayBlue bg-grayLight;
    .package-name, .package-cta {
      @apply border-grayBlue;
    }
    .package-total {
      div {
        @apply text-gray;
      }
    }
  }

  &-policies {
    @apply text-12;

    p:not(:first-child) {
      @apply mt-8;
    }
  }

  &-features {
    &--long {
      .info {
        margin-top: -2px;
        max-height: 20px;
        overflow: visible;
      }

      p {
        @apply mt-16;
      }
      ul {
        @apply mt-16 ml-0;
        list-style-type: '✓';
        list-style-position: outside;
        list-style-image: none;
        list-style: none;

        li {
          @apply pl-20 mb-16;

          &:before {
            @apply text-primary font-semibold inline-block text-16;
            content: '✓';
            width: 2rem;
            margin-left: -2rem;
          }
        }
      }
    }
  }

  .title {
    // required because of potential preferred icon (star)
    @apply pr-24;
  }

  /**
   <span class="package-features--long" v-html="ratePlanMap[offer.ratePlanId].longDescription[locale]" />
            </p>
            <!--
            <ul class="mt-16">
              <li class="flex justify-between mb-16">
                <div class="package-feature flex">
                  <div class="package-check-icon">
                    <check-icon size="18" class="text-primary mr-12"></check-icon>
                  </div>
                  <span class="text-body-small">1 × gratis Museums- oder Konzerteintritt</span>
                </div>
   */

  &-preferred-icon {
    @apply absolute top-18 right-16 z-20;
  }
}

.description-copy {
  @apply relative overflow-hidden h-auto;
  &.truncated {
    @apply transition-all duration-500 ease-in-out;
    height: 12rem;
    &:before {
      content: '';
      @apply absolute left-0 bottom-0 w-full z-20;
      height: 80%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.02) 9%, rgba(255, 255, 255, 0.06) 17%, rgba(255, 255, 255, 0.12) 24%, rgba(255, 255, 255, 0.2) 31%, rgba(255, 255, 255, 0.29) 37%, rgba(255, 255, 255, 0.39) 44%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.61) 56%, rgba(255, 255, 255, 0.71) 63%, rgba(255, 255, 255, 0.8) 69%, rgba(255, 255, 255, 0.88) 76%, rgba(255, 255, 255, 0.95) 83%, rgba(255, 255, 255, 0.98) 91%, white);
    }
  }
}

.read-more {
  -webkit-tap-highlight-color: transparent;
}

.feature-list {
  @apply grid gap-x-4 gap-y-8;
  grid-template-columns: 1fr 1fr;
}

.feature {
  @apply flex;
}

.icon {
  @apply mr-4;
}

.swiper {
  @apply w-full relative;
  @include aspect-ratio(10,8);
}
.swiper-container {
  @apply w-full h-full;
}

.swiper-slide {
  @apply bg-red-200 bg-cover bg-center;
}

.swiper-slide-thumb-active {
  @apply relative;
  &:before{
    content: '';
    @apply absolute top-0 left-0 right-0 bottom-0 border-3 border-primary;
  }
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  @apply cursor-pointer;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.description-copy {
  p {
    margin-bottom: 1.3rem;
  }
}

.shadow-modal {
  box-shadow: -16px 0 48px 0 rgba(57, 64, 69, 0.24);
}

.btn-thumbs-next {
  background-image: linear-gradient(to right, $light, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.88) 76%);
  pointer-events: none;

  button {
    top: 50%;
    transform: translateY(-50%);
    pointer-events: all;
  }
}

.btn-thumbs-prev {
  background-image: linear-gradient(to left, $light, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.88) 76%);
  pointer-events: none;

  button {
    top: 50%;
    transform: translateY(-50%);
    pointer-events: all;
  }
}

.btn-gallery-next, .btn-gallery-prev {
  pointer-events: none;

  button {
    top: 50%;
    transform: translateY(-50%);
    pointer-events: all;
  }
}
