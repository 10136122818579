



























































































































































































































































































@import '~@/styles/import';

.room-option {
	@apply grid;
	grid-template-rows: min-content auto min-content;

	.details {
		@apply cursor-pointer;
	}

	&.active {
		@apply border-3 border-primary;
	}

	&.disabled {
		.room-images, .room-image, .room-images-wrapper {
			filter: grayscale(100%);
		}

    cursor: not-allowed!important;

    * {
      cursor: not-allowed!important;
    }
	}

  &-preferred-icon {
    @apply absolute top-8 right-8 z-20;
  }
}

.room-images-wrapper {
	@apply relative h-full;
	&:before { content:''; float: left; padding-bottom: 60%;  }
	&:after  { content:''; display: table; clear: both;  }
}
// if its a single image
.room-image {
	@apply grid absolute left-0 top-0 right-0 bottom-0;
	img {
		@apply absolute left-0 top-0 w-full h-full object-cover;
	}
}
// if >= 4 images
.room-images {
	@apply grid absolute left-0 top-0 right-0 bottom-0 h-full w-full;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 0.4rem;
	.image {
		@apply relative;
	}
	img {
		@apply absolute left-0 top-0 w-full h-full object-cover;
	}
	.last {
		&:before {
			content: "";
			@apply absolute left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 block z-10;
		}
		&:after {
			content: "+"attr(data-remaining-images);
			@apply absolute top-0 left-0 flex items-center justify-center w-full h-full z-20 text-white font-semibold;
		}
	}
}

.cursor-help {
	cursor: help;
}
